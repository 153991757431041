<template>
  <div class="thirdpartnar">
    <img src="../../assets/product/thirdpartnar/1.png" class="headerimg" />
    <div class="thirdpartnarimg">
      <img src="../../assets/product/thirdpartnar/2.png" alt="" />
    </div>

    <div class="serve">
      <div class="title">服务-项目汇总</div>
      <div class="title2">做电商找邦伲德</div>

      <div class="servelist">
        <div class="servecard">
          <div class="serveimg">
            <img src="../../assets/product/thirdpartnar/3.png" alt="" />
          </div>
          <div class="serveinfo">
            <div class="servetitle">淘宝代运营</div>
            <div class="serveline">基础优化</div>
            <div class="serveline">日常托管</div>
            <div class="serveline">数据分析</div>
            <div class="serveline">流量推广</div>
          </div>
          <div class="servebottom">服务热线：400-0365558</div>
        </div>

        <div class="servecard">
          <div class="serveimg">
            <img src="../../assets/product/thirdpartnar/4.png" alt="" />
          </div>
          <div class="serveinfo">
            <div class="servetitle">天猫代运营</div>
            <div class="serveline">基础优化</div>
            <div class="serveline">日常托管</div>
            <div class="serveline">数据分析</div>
            <div class="serveline">流量推广</div>
          </div>
          <div class="servebottom">服务热线：400-0365558</div>
        </div>

        <div class="servecard">
          <div class="serveimg">
            <img src="../../assets/product/thirdpartnar/5.png" alt="" />
          </div>
          <div class="serveinfo">
            <div class="servetitle">见效果才扣费</div>
            <div class="serveline">活动策划</div>
            <div class="serveline">站外推广</div>
            <div class="serveline">店内促销</div>
            <div class="serveline">爆款打造</div>
          </div>
          <div class="servebottom">服务热线：400-0365558</div>
        </div>

        <div class="servecard">
          <div class="serveimg">
            <img src="../../assets/product/thirdpartnar/6.png" alt="" />
          </div>
          <div class="serveinfo">
            <div class="servetitle">数据监控系统</div>
            <div class="serveline">用户分析</div>
            <div class="serveline">挖掘场景</div>
            <div class="serveline">制噪话题</div>
            <div class="serveline">社交互动</div>
          </div>
          <div class="servebottom">服务热线：400-0365558</div>
        </div>
      </div>
    </div>

    <div class="vision">
      <div class="title">视觉-营销</div>
      <div class="title2">找出店铺亮点，直击用户视觉痛点，打造爆款</div>

      <div class="visionlist">
        <div class="visionimg">
          <img src="../../assets/product/thirdpartnar/7.png" alt="" />
        </div>
        <div class="visioninfo">
          <div class="visioncard">
            <div class="visiontag"></div>
            <div class="visioncardinfo">
              <div class="visiontitle">爆款设计</div>
              <div class="visiontext">主图设计与优化，上下架产品优化</div>
            </div>
          </div>
          <div class="visioncard">
            <div class="visiontag"></div>
            <div class="visioncardinfo">
              <div class="visiontitle">活动专题设计</div>
              <div class="visiontext">
                理解活动运营的真谛，明确基本工作流程和定位发力点
              </div>
            </div>
          </div>
          <div class="visioncard">
            <div class="visiontag"></div>
            <div class="visioncardinfo">
              <div class="visiontitle">商品详情页设计</div>
              <div class="visiontext">
                精细设计，直击用户视觉痛点，引导客户消费
              </div>
            </div>
          </div>
          <div class="visioncard">
            <div class="visiontag"></div>
            <div class="visioncardinfo">
              <div class="visiontitle">店铺首页装修</div>
              <div class="visiontext">突出店铺亮点，提升点击率</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="photography">
      <div class="photographylist">
        <div class="photographyinfo">
          <div class="photographytitle">摄影摄像服务</div>
          <div class="photographyline"></div>
          <div class="photographytext">
            展现商品独特视角，凸显商品的与众不同，延伸店铺品牌形象，提高点击率
          </div>
        </div>
        <div class="photographyimg">
          <img src="../../assets/product/thirdpartnar/8.png" alt="" />
        </div>
      </div>
    </div>

    <div class="flow">
      <div class="title">流量-推广</div>
      <div class="title2">快速引流，提升店铺销售额</div>

      <div class="flowlist">
        <div class="flowcard cardimg">
          <div class="flowtitle">超级推荐</div>
          <div class="flowline"></div>
          <div class="flowinfo">为店铺及宝贝i提供更多流量及展现资源</div>
        </div>
        <div class="flowcard cardimg1">
          <div class="flowtitle">直通车</div>
          <div class="flowline"></div>
          <div class="flowinfo">针对店铺的具体情况提供有效的直通车运营服务</div>
        </div>
        <div class="flowcard cardimg2">
          <div class="flowtitle">钻展</div>
          <div class="flowline"></div>
          <div class="flowinfo">
            合理使用淘宝首页上的展位，大数据助力，让钻展成为店铺的引流利器
          </div>
        </div>
      </div>
    </div>

    <div class="epiboly">
      <div class="title">客服-外包</div>
      <div class="title2">专业客服团队，提升询单转化率</div>

      <div class="epibolylsit">
        <div class="epibolycard">
          <div class="epibolyimg">
            <img src="../../assets/product/thirdpartnar/12.png" alt="" />
          </div>
          <div class="epibolytitle">在线售前客服</div>
          <div class="epibolyinfo">
            解答客户疑问，促成客户下单，做好关联销售
          </div>
        </div>
        <div class="epibolycard">
          <div class="epibolyimg">
            <img src="../../assets/product/thirdpartnar/13.png" alt="" />
          </div>
          <div class="epibolytitle">在线售后客服</div>
          <div class="epibolyinfo">
            处理客诉，退换货问题，提供消费者保障和维护品牌形象
          </div>
        </div>
        <div class="epibolycard">
          <div class="epibolyimg">
            <img src="../../assets/product/thirdpartnar/14.png" alt="" />
          </div>
          <div class="epibolytitle">客服定制</div>
          <div class="epibolyinfo">定制专业客服团队，满足个性需求</div>
        </div>
      </div>
    </div>

    <div class="technology">
      <div class="title">技术过硬-案例服人</div>
      <div class="title2">
        多年实战经验，涵盖类目广泛，官方后台真实数据，成功经验帮您解决后顾之忧
      </div>

      <div class="technologylist">
        <div class="technologyimg">
          <img src="../../assets/product/thirdpartnar/15.png" alt="" />
        </div>
        <div class="technologyinfo">
          <div class="technologytitle">家居类目</div>
          <div class="technologytext">
            【家具类目】巧用承接款推广 引爆淘客周期销量
          </div>
          <div class="technologytitle">接手前：</div>
          <div class="technologytext">
            接手前产品自身具备行业竞争优势，供货充足，产品拍摄和新品研发能力强，店主期待跟火蝠合作可以将店铺推上一个新的高度。
          </div>
          <div class="technologytitle">接手后：</div>
          <div class="technologytext">
            优化直通车词条，高溢价投放增加新品数据维度快速上分，中后期广泛匹配让曝光量扩大.
          </div>
        </div>
      </div>
    </div>

    <div class="cooperation">
      <div class="title">合作伙伴</div>
      <div class="title2">
        知名品牌商家合作，互利共赢，实现品牌价值
      </div>
      <div class="cooperationimg">
        <img src="../../assets/product/thirdpartnar/16.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.cooperation{
  margin-bottom: 140px;
  .cooperationimg{
    margin-top: 60px;
    text-align: center;
    img{
      width: 1200px;
    }
  }
}

.technology {
  margin: 140px auto;
  .technologylist {
    display: flex;
    justify-content: center;
    margin-top: 60px;
    .technologyimg {
      img {
        width: 828px;
      }
    }
    .technologyinfo {
      padding: 90px;
      width: 572px;
      height: 539px;
      box-sizing: border-box;
      background: #f5f7fa;
      .technologytitle {
        font-size: 24px;
        font-weight: 400;
        color: #e02020;
        line-height: 33px;
        margin-bottom: 20px;
      }
      .technologytext {
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
         margin-bottom: 40px;
      }
    }
  }
}

.epiboly {
  padding: 90px;
  background: #f5f7fa;
  .epibolylsit {
    width: 80%;
    margin: auto;
    margin-top: 46px;
    display: flex;
    justify-content: space-between;
    .epibolycard {
      width: 397px;
      height: 357px;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      text-align: center;
      padding: 60px 47px;
      box-sizing: border-box;
      .epibolyimg {
        img {
          width: 90px;
        }
      }
      .epibolytitle {
        margin-top: 60px;
      }
      .epibolyinfo {
        margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #616b80;
        line-height: 20px;
      }
    }
  }
}

.flow {
  margin-top: 140px;
  margin-bottom: 126px;
  .flowlist {
    width: 70%;
    margin: auto;
    margin-top: 46px;
    display: flex;
    justify-content: space-between;
    .cardimg {
      background-image: url("../../assets/product/thirdpartnar/9.png");
    }
    .cardimg1 {
      background-image: url("../../assets/product/thirdpartnar/10.png");
    }
    .cardimg2 {
      background-image: url("../../assets/product/thirdpartnar/11.png");
    }
    .flowcard {
      width: 465px;
      height: 456px;
      background-size: 100% 100%;
      color: #fff;
      text-align: center;
      .flowtitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        margin-top: 179px;
      }
      .flowline {
        width: 38px;
        height: 1px;
        background: #fff;
        margin: auto;
        margin-top: 20px;
        margin-bottom: 39px;
      }
      .flowinfo {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        max-width: 309px;
        margin: auto;
      }
    }
  }
}

.photography {
  padding: 90px;
  background: #f5f7fa;
  margin-top: 60px;
  .photographylist {
    justify-content: center;
    display: flex;
    align-items: center;
    .photographyinfo {
      .photographytitle {
        width: 350px;
        font-size: 80px;
        font-weight: 500;
        color: #333333;
        line-height: 112px;
      }
      .photographyline {
        width: 97px;
        height: 3px;
        background: #e02020;
        margin-top: 23px;
        margin-bottom: 81px;
      }
      .photographytext {
        width: 581px;
        font-size: 24px;
        font-weight: 400;
        color: #616b80;
        line-height: 33px;
      }
    }
    .photographyimg {
      margin-left: 145px;
      width: 673px;
      img {
        width: 100%;
      }
    }
  }
}

.vision {
  margin-top: 140px;
  .visionlist {
    margin-top: 68px;
    display: flex;
    justify-content: center;
    align-items: center;
    .visionimg {
      width: 742px;
      img {
        width: 100%;
      }
    }
    .visioninfo {
      margin-left: 110px;
      .visioncard {
        width: 547px;
        height: 123px;
        background: #ffffff;
        box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
        padding: 30px;
        box-sizing: border-box;
        margin-top: 20px;
      }
    }
    .visiontag {
      display: inline-block;
      height: 44px;
      width: 6px;
      background-color: #e02020;
    }
    .visioncardinfo {
      display: inline-block;
      margin-left: 20px;
      .visiontitle {
        font-size: 24px;
        font-weight: 500;
        line-height: 33px;
      }
      .visiontext {
        margin-top: 10px;
        font-size: 14px;
        font-weight: 500;
        color: #616b80;
        line-height: 20px;
      }
    }
  }
}

.serve {
  margin-top: 127px;
  .servelist {
    width: 1417px;
    margin: auto;
    margin-top: 60px;
    display: flex;
    justify-content: space-between;
    .servecard {
      text-align: center;
      width: 325px;
      height: 444px;
      background: #ffffff;
      box-shadow: 0px 0px 14px 0px rgba(0, 0, 0, 0.2);
      box-sizing: border-box;
      position: relative;
      .servebottom {
        font-size: 18px;
        font-weight: 400;
        color: #616b80;
        position: absolute;
        bottom: 0;
        height: 60px;
        line-height: 60px;
        width: 100%;
        margin-bottom: 0;
        background: #f9f9f9;
      }
      .serveinfo {
        margin-top: 29px;
        .servetitle {
          font-size: 24px;
          font-weight: 500;
          line-height: 36px;
          margin-bottom: 10px;
        }
        .serveline {
          margin-top: 10px;
          font-size: 14px;
          font-weight: 400;
          color: #616b80;
          line-height: 20px;
        }
      }
      .serveimg {
        padding-top: 50px;
        padding-bottom: 10px;
        border-bottom: 1px solid #e02020;
        display: inline-block;
        img {
          width: 204px;
        }
      }
    }
  }
}

.thirdpartnarimg {
  text-align: center;
  margin-top: -3px;
  img {
    vertical-align: top;
    width: 1417px;
  }
}
</style>
